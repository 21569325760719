<template>
  <div>
    <v-btn
      v-if="user.role !== 'Manager'"
      :to="{ name: 'invoicesCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
    >
      ДОБАВИ НОВА ФАКТУРА</v-btn
    >
    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <!-- <v-checkbox v-model="tableOptions.showSearch" 
                      label="Търси по колона" 
                      class="shrink"></v-checkbox>  -->
        </v-col>

        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <template v-for="column of headers.columns">
          <v-col
            :key="column.name"
            :cols="column.cols"
            v-if="column.hide != true"
          >
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>
        <v-col cols="2">
          Операции
        </v-col>
      </v-row>

      <div v-show="!isLoadingInvoices">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of headers.columns">
            <v-col
              class="t-col"
              :key="col.name"
              :cols="col.cols"
              v-if="col.hide != true"
            >
              <template v-if="col.format">
                {{ formatDate(item[col.name], col.format) }}
              </template>
              <template v-else>
                {{ item[col.name] }}
              </template>
            </v-col>
          </template>

          <v-col
            cols="2"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <EditButton
              v-if="user.role !== 'Manager'"
              :id="item.id"
              action-name="invoicesCreateUpdate"
            />
            <DownloadPdfButton
              :id="item.id"
              file-type="invoice"
              text="Изтегли фактура"
              list-action="invoicesList"
            />
            <LogLink :id="item.id" type="invoice" class-name="row" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingInvoices"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_INVOICES } from "@/store/invoices.module";

import { mapGetters } from "vuex";

import Paginator from "@/components/Paginator";

import ListMixin from "@/common/mixins/List";
import LogLink from "@/components/Log/LogLink";
import EditButton from "@/components/ActionButtons/EditButton";
import DownloadPdfButton from "@/components/ActionButtons/DownloadPdfButton";

import moment from "moment";

export default {
  components: { LogLink, EditButton, DownloadPdfButton, Paginator },
  mixins: [ListMixin],
  data() {
    return {
      action: "Invoices",
      items: [],
      totalItems: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "number",
            search: ""
          },
          {
            name: "client.name",
            search: ""
          },
          {
            name: "date",
            format: "DD.MM.YYYY"
          }
        ],
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingInvoices"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    }
  },
  watch: {
    "headers.search": _.debounce(function(new_value) {
      let vm = this;

      vm.headers.page = 1;
      if (new_value == null || new_value.length >= 3) {
        vm.fetchData();
      }
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Фактури" }]);
    vm.fetchData();
  },
  methods: {
    formatDate: function(rr, format) {
      return moment(rr).format(format);
    },
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    fetchData() {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_INVOICES, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "invoicesList" });
          }
        });
    }
  },
  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
